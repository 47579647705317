var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-1 pb-2"},[_c('v-row',{staticClass:"my-3",attrs:{"justify":"center"}}),_c('v-data-table',{staticClass:"elevation-1 tb",attrs:{"headers":_vm.Headers,"items":_vm.enquiries,"search":_vm.search,"custom-filter":_vm.filterEnquiries},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{staticClass:"mb-0",attrs:{"cols":"12","md":"4"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("All Enquiries")])],1)],1),_c('v-col',{staticClass:"mb-0",attrs:{"cols":"12","md":"8"}},_vm._l((_vm.admins),function(admin){return _c('v-btn',{key:admin.title,staticClass:"mx-2 mt-5",attrs:{"dense":"","small":"","color":"secondary"},on:{"click":function($event){_vm.search = admin.title}}},[_vm._v(" "+_vm._s(admin.title)+" ")])}),1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.treated",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"true-value":1,"false-value":0},on:{"change":function($event){return _vm.updateRecord(item)}},model:{value:(item.treated),callback:function ($$v) {_vm.$set(item, "treated", $$v)},expression:"item.treated"}})]}},{key:"item.treated_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.treated == 1 ? _vm.getCurUser : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":"","title":"Click to delete this enquiry"},on:{"click":function($event){return _vm.delRecord(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('v-snackbar',{attrs:{"bottom":"","color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Close ")],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('p',[_vm._v(_vm._s(_vm.snackMsg))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }