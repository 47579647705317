<template>
  <div class="px-1 pb-2">
    <v-row justify="center" class="my-3"> </v-row>
    <v-data-table
      :headers="Headers"
      :items="enquiries"
      class="elevation-1 tb"
      :search="search"
      :custom-filter="filterEnquiries"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-col cols="12" md="4" class="mb-0">
            <v-toolbar flat>
              <v-toolbar-title>All Enquiries</v-toolbar-title>
            </v-toolbar>
          </v-col>
          <v-col cols="12" md="8" class="mb-0">
            <v-btn
              dense
              small
              v-for="admin in admins"
              :key="admin.title"
              class="mx-2 mt-5"
              @click="search = admin.title"
              color="secondary"
            >
              {{ admin.title }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="mb-5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.treated="{ item }">
        <v-checkbox
          @change="updateRecord(item)"
          v-model="item.treated"
          :true-value="1"
          :false-value="0"
        ></v-checkbox>
      </template>
      <template v-slot:item.treated_by="{ item }">
        {{ item.treated == 1 ? getCurUser : "" }}
      </template>
      <template #item.actions="{ item }">
        <v-btn
          icon
          x-small
          @click="delRecord(item)"
          title="Click to delete this enquiry"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { mapState, mapGetters, mapActions } from "vuex";
import { snackMixin } from "@/mixins";
export default {
  name: "Enquiries",
  mixins: [snackMixin],
  data() {
    return {
      expanded: [],
      search: "",
      panel: [0, 1, 2],
      disabled: false,
      treated: "",
      Headers: [
        { text: "Name", value: "fullname" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phoneno" },
        { text: "Subject", value: "subject" },
        { text: "Message", value: "message" },
        { text: "Treated", value: "treated" },
        { text: "Treated By", value: "treated_by" },
        { text: "Date", value: "created_on" },
        { text: "Actions", value: "actions" },
      ],
      // enquiries: [],
      admins: [
        { title: "General" },
        { title: "Bookstore" },
        { title: "Offices" },
        { title: "Hall" },
        { title: "Treated" },
        { title: "Untreated" },
      ],
    };
  },
  computed: {
    ...mapState(["curUser", "enquiries"]),
    ...mapGetters(["getCurUser"]),
  },
  async created() {
    await apiClient
      .get("/enquiries?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.setEnquiries(res.data);
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message));
  },
  methods: {
    ...mapActions(["setEnquiries"]),
    filterEnquiries(value, search, item) {
      switch (search) {
        case "General":
          return item.category == "general";
        case "Bookstore":
          return item.category == "bookstore";
        case "Offices":
          return item.category == "offices";
        case "Hall":
          return item.category == "hall";
        case "Treated":
          return item.treated == 1;
        case "Untreated":
          return item.treated == 0;
        default:
          return (
            value != null &&
            search != null &&
            value.toString().indexOf(search) !== -1
          );
      }
    },
    updateRecord(record) {
      let data = {
        id: record.id,
        treated: record.treated,
        treated_by: this.getCurUser,
        type: "treated",
      };
      apiClient
        .put("/enquiries", data)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully");
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => this.displayMsg(err.message, "error"));
    },
    delRecord(record) {
      const ok = confirm(`Are you sure you want to delete ${record.fullname}?`);
      if (ok) {
        this.displayMsg(`Deleting ${record.fullname}, please wait...`);
        apiClient
          .delete(`/enquiries?${record.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${record.fullname} not successfully deleted`,
                "error"
              );
              return;
            }
            this.enquiries = this.enquiries.filter(
              (rec) => rec.id !== record.id
            );
            this.displayMsg(`${record.fullname} successfully deleted`);
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    },
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
